<template>
  <div class="card-wrap">
    <div class="card">
      <!-- <img class="flower" src="./../assets/1.png" alt="flower" /> -->
      <p class="title">
        <span v-if="reference">{{reference + ','}}</span> kviečiame
        <br />į mūsų vestuves!
      </p>
      <p class="title--fancy">Giedrė ir Vladis</p>
      <img class="flower2" src="./../assets/1.png" alt="flower" />
      <div class="block">
        <h2>Kada?</h2>
        <p>2021 m. liepos 24 diena</p>
      </div>
      <div class="block">
        <h2>Kur?</h2>
        <div class="where">
          <p class="item">12 val. Tuoktuvių ceremonija Šv. Jonų bažnyčioje (Šv. Jono g. 12, Vilnius)</p>
        </div>
      </div>
      <!-- <img class="flower3" src="./../assets/3.png" alt="flower" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  /* 1rem = 10px */
  font-size: 45%;
  @media (max-width: 800px) {
    font-size: 30%;
  }
}
body {
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  background: #e5e5e5;
  color: #808080;
  line-height: 1.5;
}
.card {
  &-wrap {
    max-width: 123rem;
    margin: 3rem auto;
    background-image: url(./../assets/bg9.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 500px) {
      background-image: url(./../assets/bg9-mob.jpg);
    }
    // @media (max-width: 350px) {
    //   background-size: cover;
    // }
  }
  padding: 25rem 25rem 12rem;
  text-align: center;
  @media (max-width: 500px) {
    padding: 13%;
  }
  // @media (max-width: 350px) {
  //   padding: 9%;
  // }
}
.title {
  text-transform: uppercase;
  font-size: 3rem;
  &--fancy {
    font-size: 10rem;
    font-family: "Great Vibes", cursive;
    @media (max-width: 500px) {
      font-size: 8rem;
    }
  }
}
.flower {
  width: 8rem;
  height: auto;
  margin-bottom: 2.8rem;
}
.block {
  margin-bottom: 4rem;
  h2 {
    font-size: 6rem;
    font-family: "Great Vibes", cursive;
    font-weight: 400;
  }
  p {
    font-size: 2.4rem;
    font-weight: 500;
  }
}
.flower2 {
  width: 6rem;
  height: auto;
  margin-bottom: 3rem;
  @media (max-width: 500px) {
    display: none;
  }
}
.flower3 {
  width: 22rem;
  height: auto;
  margin-bottom: 3rem;
}
.gift {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  &__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 2.8rem;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
    }
    &:first-of-type {
      margin-right: 2rem;
    }
    img {
      width: 6rem;
      height: auto;
      margin-right: 2rem;
    }
  }
}
.where {
  display: flex;
  justify-content: center;
  max-width: 60rem;
  margin: 0 auto;
  .item {
    width: 50%;
    padding: 0 2rem;
    font-size: 2rem;
  }
}
.subtitle {
  text-align: left;
  font-size: 2rem;
}
</style>
